import { useEffect, useRef } from 'react'
import CorpdataService from './CorpdataService';

var sessionStartTimeMs = new Date().getTime();
var userEmail = undefined;

function setLoggedEmail(email) {
  userEmail = email;
}

function logEvent(eventName) {
  const event = constructEvent(eventName);
  CorpdataService.sharedInstance().ingestAnalytics([event]);
}

function constructEvent(eventName) {
  return {
    device_id: sessionStartTimeMs.toString(),
    session_id: sessionStartTimeMs,
    user_id: userEmail,
    event_type: eventName,
    platform: 'Web',
    timestamp: new Date().getTime()
  };
}

const DEBOUNCE_DURATION = 1000

const useValueChangeLogger = (valueWatched, eventName) => {
  const timeout = useRef()

  useEffect(() => {
    if (!valueWatched) { return }
    if (timeout.current) { clearTimeout(timeout.current) }

    timeout.current = setTimeout(() => {
      logEvent(eventName)
    }, DEBOUNCE_DURATION)
  }, [valueWatched, eventName])
}

export { logEvent, setLoggedEmail, useValueChangeLogger };
