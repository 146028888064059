import React from 'react';
import fieldLabels from '../utils/fieldLabels'

const ReviewSubmission = ({ values }) => {
  return (
    <>
      <h2>Customer Details</h2>
      <section className="review-section">
        <div className="review-columns">
          <div id="review-soldToName" className="review-field-pairs">
            <span className="field-key">Contact Name</span>
            <span className="field-value">{values.firstName} {values.lastName}</span>
          </div>

          <div id="review-soldToEmail" className="review-field-pairs">
            <span className="field-key">{fieldLabels['email']}</span>
            <span className="field-value">{values.email}</span>
          </div>
        </div>
        <div className="review-columns">
          <div id="review-companyName" className="review-field-pairs">
            <span className="field-key">{fieldLabels['companyName']}</span>
            <span className="field-value">{values.companyName}</span>
          </div>

          <div id="review-companySlug" className="review-field-pairs">
            <span className="field-key">{fieldLabels['companySlug']}</span>
            <span className="field-value">{values.companySlug}</span>
          </div>
        </div>
      </section>

      <h2>Technical Details</h2>
      <section className="review-section">
        <div className="review-columns">
          <div id="review-primaryCloudProvider" className="review-field-pairs">
            <span className="field-key">Cloud Provider</span>
            <span className="field-value">{(values.primaryCloudProvider instanceof Array) ? values.primaryCloudProvider.join(', ') : ''}</span>
          </div>

          <div id="review-awsAccountId" className="review-field-pairs">
            <span className="field-key">{fieldLabels['awsAccountId']}</span>
            <span className="field-value">{values.awsAccountId}</span>
          </div>

          <div id="review-awsRegion" className="review-field-pairs">
            <span className="field-key">{fieldLabels['awsRegion']}</span>
            <span className="field-value">{values.awsRegion}</span>
          </div>

          <div id="review-tectonARN" className="review-field-pairs">
            <span className="field-key">{fieldLabels['tectonARN']}</span>
            <span className="field-value">{values.tectonARN}</span>
          </div>

          <div id="review-glueCatalogAccountId" className="review-field-pairs">
            <span className="field-key">{fieldLabels['glueCatalogAccountId']}</span>
            <span className="field-value">{values.glueCatalogAccountId}</span>
          </div>
        </div>

        <div className="review-columns">
          <div id="review-primaryComputeEngine" className="review-field-pairs">
            <span className="field-key">Compute Engines</span>
            <span className="field-value">{(values.primaryComputeEngine instanceof Array) ? values.primaryComputeEngine.join(', ') : ''}</span>
          </div>

          <div id="review-databricksSlug" className="review-field-pairs">
            <span className="field-key">{fieldLabels['databricksSlug']}</span>
            <span className="field-value">{values.databricksSlug}</span>
          </div>

          <div id="review-databricksToken" className="review-field-pairs">
            <span className="field-key">{fieldLabels['databricksToken']}</span>
            <span className="field-value">{values.databricksToken}</span>
          </div>

          <div id="review-databricksARN" className="review-field-pairs">
            <span className="field-key">{fieldLabels['databricksARN']}</span>
            <span className="field-value">{values.databricksARN}</span>
          </div>
        </div>

      </section>
    </>
  );
};

export default ReviewSubmission;
