// Based on https://docs.databricks.com/administration-guide/cloud-configurations/aws/regions.html
// Including non-E2 regions
const AWS_REGIONS = [
  "us-west-1",
  "us-west-2",
  "us-east-1",
  "us-east-2",
  "ca-central-1",
  "sa-east-1",
  "eu-west-1",
  "eu-west-2",
  "eu-west-3",
  "eu-central-1",
  "ap-south-1",
  "ap-southeast-1",
  "ap-southeast-2",
  "ap-northeast-1",
  "ap-northeast-2"
];

export {
  AWS_REGIONS
}
