import React, { useEffect, useState } from 'react'
import { Field, ErrorMessage, useFormikContext } from 'formik';
import ButtonWithMotionFeedback from '../ButtonWithMotionFeedback'
import fieldLabels from '../../utils/fieldLabels'
import ValidationIndicator, {
  UNVALIDATED,
  WAITING_FOR_VALIDATION,
  VALID_CREDENTIALS,
  INVALID_CREDENTAILS
} from "../ValidationIndicator"

/*
const realPromise = async () => {
  const res = await fetch("https://api.example.com/items");
  return await res.json();
}
*/

const fakePromise = async () => {
  await new Promise(resolve => {
    setTimeout(resolve, 600)
  })
  return {
    status: (Math.random() > 0.5) ? "FAILED" : "SUCCESS"
  }
}

function DatabricksAccessForm() {
  const {
    setTouched,
    setFieldValue,
    values: {
      databricksSlug,
      databricksToken
    },
    errors
  } = useFormikContext()

  const [validationStatus, setValidationStatus] = useState(UNVALIDATED)

  const validateCredentials = () => {
    // Set waiting flag. Spinner will show based on flag
    setValidationStatus(WAITING_FOR_VALIDATION)

    const databricksValidated = 'databricksValidated'

    fakePromise()
    .then(
      (result) => {
        if (result.status !== "SUCCESS") {
          setValidationStatus(INVALID_CREDENTAILS)
          setFieldValue(databricksValidated, false)
        } else {
          setValidationStatus(VALID_CREDENTIALS)
          setFieldValue(databricksValidated, true)
        }
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        console.log('handle fetch error')
      }
    )
  }

  // As soon as any of awsAccountId, awsRegion, or tectonARN changes,
  // mark the set as UNVALIDATED
  useEffect(() => {
    setValidationStatus(UNVALIDATED)
  }, [
    databricksToken,
    databricksSlug
  ])

  // Ready to Submit
  const isReady = (
    validationStatus !== INVALID_CREDENTAILS &&
    !errors.databricksToken &&
    !errors.databricksSlug
  )

  const handleValidationButton = () => {
    if (isReady) {
      validateCredentials()
    } else {
      setTouched({
        databricksToken: true,
        databricksSlug: true
      }, true)
    }
  }

  return (
    <div id="databricksValidated">
      <label htmlFor="databricksSlug">{fieldLabels['databricksSlug']}</label>
      <Field id="databricksSlug" type="text" name="databricksSlug" />
      <ErrorMessage name="databricksSlug" component="div" className="field-error-msg" />

      <label htmlFor="databricksToken">{fieldLabels['databricksToken']}</label>
      <Field id="databricksToken" type="text" name="databricksToken" />
      <ErrorMessage name="databricksToken" component="div" className="field-error-msg" />

      <ButtonWithMotionFeedback
        type="button"
        isReady={isReady}
        onClick={handleValidationButton}
      >
        Validate
      </ButtonWithMotionFeedback>

      <ValidationIndicator
        validationStatus={validationStatus}
        waitingMsg={"Connecting to Databricks..."}
      />
    </div>
  )
}

export default DatabricksAccessForm
