import React from 'react'
import { Field, ErrorMessage, useFormikContext } from 'formik';
import fieldLabels from '../../utils/fieldLabels';
import { useValueChangeLogger } from '../../utils/analyticsUtils';


function GlueCatalogForm() {
  const formik = useFormikContext();

  useValueChangeLogger(formik.values.glueCatalogAccountId, "Edit – Glue Catalog Account ID")

  return (
    <div>
      <label htmlFor="glueCatalogAccountId">{fieldLabels['glueCatalogAccountId']}</label>
      <Field id="glueCatalogAccountId" type="text" name="glueCatalogAccountId" />
      <ErrorMessage name="glueCatalogAccountId" component="div" className="field-error-msg" />
    </div>
  )
}

export default GlueCatalogForm
