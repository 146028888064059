import React from 'react';

import PolicyTemplate from './PolicyTemplate'
import AWSInfoForm from './AWSInfoForm'
import GlueCatalogForm from './GlueCatalogForm'
import DatabricksAccessForm from './DatabricksAccessForm'
import DatabricksARNform from './DatabricksARNform'

function TechnicalIntakeForm({ awsExternalID }) {
  return (
    <>
      <section id="lets-get-started" className="top-level-sections">
        <h2>Let&rsquo;s Get Started</h2>
        <p className="major">
          Tecton employs a SaaS deployment model, where we process and store sensitive data in your account, and
          run everything else in Tecton's account. To get Tecton up and running, we need
          <strong>3 sets of access information</strong>.
        </p>

        <ul id="sets-of-access">
          <li>
            <h3>
              <img className="key-letter key-letter-a" src="/letter-a.svg" alt="A. AWS" />
              AWS
            </h3>
            <p>
              To ensure that your data stays isolated, we use AWS managed services (i.e. DynamoDB
              and S3 Buckets) to store transformed feature data in your account. This this is why
              we need the AWS IAM role.
            </p>
          </li>
          <li>
            <h3>
              <img className="key-letter key-letter-b" src="/letter-b.svg" alt="B. Databricks" />
              Databricks
            </h3>
            <p>To orchestrate materializations jobs without accessing your data, we need access
              to your Databricks account. This is the purpose for the Databricks access.</p>
          </li>
          <li>
            <h3>
              <img className="key-letter key-letter-c" src="/letter-c.svg" alt="C. Data Access from Databricks" />
              Instance Profile
            </h3>
            <p>Similarly, we also need to know the name of the instance profile that
              Databricks will use to access your data.</p>
          </li>
        </ul>

        <img
          style={{width: "100%"}}
          src="/deployment-diagram.png"
          alt="Tecton Deployment Diagram - Why we need access to AWS and Databricks."
        />
        <p>
          Tecton’s control plane (left side of the diagram) resides in Tecton’s AWS account,
          and it is where our Feature Server, Metadata API, and other system components resides.</p>

        <p className="major">In the next section, we will gather and verify all of this information.</p>
      </section>
      <section id="aws-section" className="top-level-sections credential-sections">
        <section className="topic-overview">
          <h2><img className="key-letter key-letter-a" src="/letter-a.svg" alt="A." /> An AWS IAM role, with permissions to use select AWS managed services.</h2>
          <p>
            Tecton’s control plane will assume this role in AWS to deploy the components required for Tecton’s
            Feature Store. Tecton only uses AWS managed services in your account i.e. DynamoDB and S3 buckets.
          </p>
        </section>
        <section className="topic-copy">
          <h3>Instructions</h3>
          <ol className="instructions-list">
            <li>Go to your AWS console. Create a new role in your AWS account. </li>
            <li>Fill in your AWS Account ID and region on the right.</li>
            <li>Copy the External ID from the right and ...do what exactly?...</li>
            <li>
              Copy the generated permission policy and paste it into the permissions section of the role you’ve created.
              <div id="policy-template">
                <PolicyTemplate />
              </div>
            </li>
            <li>Copy the generated trust relationship JSON into ...</li>
            <li>Click Validate Access.</li>
          </ol>
          <p className="small-notes">Having trouble? See <a href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_roles_common-scenarios_third-party.html"rel="noopener noreferrer" target="_blank">AWS’s user guide on Providing access to AWS accounts owned by third parties for more detail</a>.</p>
        </section>
        <section className="form-section" id="aws-access-form">
          <AWSInfoForm
            externalID={awsExternalID}
          />
        </section>
        <section className="topic-copy">
          <h3>Optional: Glue Catalog</h3>
          <p>
            If you plan to use Hive as a <a href="https://docs.tecton.ai/overviews/data_sources.html"rel="noopener noreferrer" target="_blank">data source</a>, provide
            the Glue Catalog ID here. We can get it all set up in
            one go.
          </p>
        </section>
        <section className="form-section" id="glue-access-form">
          <GlueCatalogForm />
        </section>
      </section>
      <section id="databricks-section" className="top-level-sections credential-sections">
        <section className="topic-overview">
          <h2><img className="key-letter key-letter-b" src="/letter-b.svg" alt="B." /> Access to Databricks.</h2>
          <p>
            Tecton will launch Spark jobs in Databricks to materialize features to the Feature Store.
            Your Databricks account will need to have access to the raw data sources you will build features from.
          </p>
        </section>
        <section className="topic-copy">
          <h3>Instructions</h3>
          <ol className="instructions-list">
            <li>Get your Databricks account slug. This is simply be the first part of the URL you use to access the Databricks UI. Paste the account slug into the form on the right.</li>
            <li>In the Databricks workspace UI, click the user profile icon in the upper right. In User Settings, go to the Access Token tab.</li>
            <li>Click the Generate New Token button, and copy to the token into the form on the right.</li>
            <li>Click Validate Access.</li>
          </ol>
          <p className="small-notes">Having trouble? See <a href="https://docs.databricks.com/dev-tools/api/latest/authentication.html" rel="noopener noreferrer" target="_blank">
            Databricks’ instructions for generated Access Token for more detail
          </a>.</p>
        </section>
        <section className="form-section" id="databricks-access-form">
          <DatabricksAccessForm />
        </section>
      </section>
      <section id="instance-profile-section" className="top-level-sections credential-sections">
        <section className="topic-overview">
          <h2><img className="key-letter key-letter-c" src="/letter-c.svg" alt="C." /> Databricks Instance Profile ARN.</h2>
          <p>
            Tecton does not access your data directly, only through Databricks. We do need to know the ARN of
            the instance profile that Databricks uses to access your data, in order to start data processing jobs
            (e.g. feature materialization.)
          </p>
        </section>
        <section className="topic-copy">
          <h3>Instructions</h3>
          <ol className="instructions-list">
            <li>In the Databricks workspace UI, click the user profile icon in the upper right. Navigate to Admin Console, and go to the Instance Profile tab.</li>
            <li>In the table of Instance Profiles, find the ARN of the one which has access to your data sources. Paste it into the form field on the right.</li>
            <li>Click Validate Access</li>
          </ol>
          <p className="small-notes">Having trouble? See ...</p>
        </section>
        <section className="form-section" id="databricks-arn-form">
          <DatabricksARNform />
        </section>
      </section>
    </>
  );
}

export default TechnicalIntakeForm;
