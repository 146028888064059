import React from 'react';
import ValidationIndicator, {
  WAITING_FOR_VALIDATION,
  INVALID_CREDENTAILS
} from "./ValidationIndicator"

const FormValidationFeedback = ({
  isSubmitting,
  submissionWasInvalid,
  errorMessage
  }) => {
  let formValidationStatus = null

  if (isSubmitting) {
      formValidationStatus = WAITING_FOR_VALIDATION
  } else {
      if (submissionWasInvalid) { formValidationStatus = INVALID_CREDENTAILS }
  }

  return <ValidationIndicator
      validationStatus={formValidationStatus}
      waitingMsg={"Submitting..."}
      invalidMsg={errorMessage}
  />
}

export default FormValidationFeedback;
