const fieldLabels = {
  // Company Info
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  companyName: 'Company/Organization Legal Name',
  companySlug: 'Company/Organization Identifier',
  primaryCloudProvider: 'What cloud are you currently using? (select all that apply)',
  primaryComputeEngine: 'What do you currently use as for compute? (select all that apply)',

  // AWS
  awsAccountId: 'AWS Account ID',
  awsRegion: 'AWS Region',
  tectonARN: 'ARN of the role created for Tecton',
  awsExternalId: 'External ID to use for Tecton',
  awsValidated: 'AWS Credential Validation',

  glueCatalogAccountId: 'Glue Catalog ID',

  // Databricks
  databricksSlug: 'Databricks Account Identifier',
  databricksToken: 'Databricks Access Token',
  databricksValidated: 'Databricks Credential Validation',

  // Databricks ARN
  databricksARN: 'Databricks-to-AWS ARN',
  dbARNValidated: 'Databricks-to-AWS Credential Validation'
}

export default fieldLabels
