import React from 'react';

const UNVALIDATED = 'UNVALIDATED'
const WAITING_FOR_VALIDATION = 'WAITING_FOR_VALIDATION'
const VALID_CREDENTIALS = 'VALID_CREDENTIALS'
const INVALID_CREDENTAILS = 'INVALID_CREDENTIALS'

function ValidationIndicator({
  validationStatus,
  waitingMsg,
  validMsg,
  invalidMsg,
}) {

  switch (validationStatus) {
    case WAITING_FOR_VALIDATION:
      return (
        <div className="validation-indicator">
          <svg width="21" height="21" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g className="waiting-rotating-arrow">
              <circle cx="21" cy="21" r="21" fill="#A2ACB7"/>
              <path
                d="M32.7392 18.5L31.9238 6.98182L22.3565 13.4471L32.7392 18.5ZM21 32C14.9249 32 10 27.0751 10 21H8C8 28.1797 13.8203 34 21 34V32ZM10 21C10 14.9249 14.9249 10 21 10V8C13.8203 8 8 13.8203 8 21H10ZM21 10C23.2759 10 25.3877 10.6901 27.1411 11.8724L28.2592 10.2141C26.1858 8.81611 23.6867 8 21 8V10ZM31.7609 23.2927C30.7067 28.2676 26.2881 32 21 32V34C27.2522 34 32.4714 29.5874 33.7175 23.7073L31.7609 23.2927Z"
                fill="white"
              />
            </g>
          </svg>
          {waitingMsg}
        </div>
      )

    case VALID_CREDENTIALS:
      return (
        <div className="validation-indicator">
          <svg width="21" height="21" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="21" cy="21" r="21" fill="#27AE60"/>
            <path d="M8 21.5625L16.2979 30L34 12" stroke="white" strokeWidth="5"/>
          </svg>
          {validMsg}
        </div>
      )

    case INVALID_CREDENTAILS:
      return (
        <div className="validation-indicator">
          <svg width="21" height="21" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="21" cy="21" r="21" fill="#F2994A"/>
            <path d="M11 12L21 21.5M31 31L21 21.5M21 21.5L31 12L11 31" stroke="white" strokeWidth="6"/>
          </svg>
          {invalidMsg}
        </div>
      )

    default:
      return null;
  }
}

ValidationIndicator.defaultProps = {
  waitingMsg: "Validating...",
  validMsg: "Access Validated",
  invalidMsg: "Invalid Credentials"
}

export default ValidationIndicator;
export {
  UNVALIDATED,
  WAITING_FOR_VALIDATION,
  VALID_CREDENTIALS,
  INVALID_CREDENTAILS
}
