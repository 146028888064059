import React from 'react';

// cluster_valid
// cluster_invalid
// unable_to_reach_server

function ValidationFeedback({ state }) {
  if (state.matches('processing')) {
    return <div className="feedback feedback-normal">
      <p>Please wait while we validate permissions on your cluster.</p>
    </div>
  }

  if (state.matches('cluster_valid')) {
    return <div className="feedback feedback-success">
      <p>
        Success! Permissions on your cluster are configured properly and Tecton
        is able to read from your cluster.
      </p>
    </div>
  }

  if (state.matches('cluster_invalid')) {
    return <div className="feedback feedback-error">
      <p>Validation Error:</p>
      <p>{state.context.errorMessage}</p>
    </div>
  }

  if (state.matches('unable_to_reach_server')) {
    return <div className="feedback feedback-error">
      <p>We were unable to reach Tecton servers. Our servers may be
        down momentarily, or there may be an issue with your
        internet connection.
      </p>
      <p>Please try again, or contact us.</p>
    </div>
  }

  // State is either 'idle' or in a condition we did not consider
  return null
}

export default ValidationFeedback;
