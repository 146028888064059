import React from 'react';

function SorryWeDontSupportYourStackYet(props) {
  return (
    <section  className="top-level-sections">
      <div id="sorry-we-do-not-support-your-stack-yet">
        <h1>Self Serve Support Coming Soon</h1>
        <p>
          Unfortunately, self serve sign up is currently limited to customers using Databricks with AWS.
        </p>
        <p>
          In order to support you, a member of our solutions team will reach out shortly. We look forward
          bringing you better feature serving soon.
        </p>
        <p>Your Tecton Team</p>
      </div>
    </section>
  );
}

export default SorryWeDontSupportYourStackYet;
