import React from 'react';
import fieldLabels from '../utils/fieldLabels'

const fieldOrder = [
  'firstName',
  'lastName',
  'email',
  'companyName',
  'companySlug',
  'primaryCloudProvider',
  'primaryComputeEngine',
  'awsAccountId',
  'awsRegion',
  'tectonARN',
  'awsExternalId',
  'awsValidated',
  'databricksSlug',
  'databricksToken',
  'databricksValidated',
  'databricksARN',
  'dbARNValidated'
]

function ErrorsSummary({ errors }) {
  const errorEntries = Object.entries(errors)

  if (errorEntries.length <= 0) { return null }

  return (
    <div id="form-error-summary">
      <p>We found the following errors in the form. Please correct these and try again.</p>
      <table>
        <thead>
          <tr>
            <th className="error-table-key">Field</th>
            <th className="error-table-value">Error</th>
          </tr>
        </thead>
        <tbody>
        {Object.entries(errors)
        .sort((a, b) => {
          return fieldOrder.indexOf(a[0]) - fieldOrder.indexOf(b[0])
        })
        .map(([key, value]) => {
          const scrollCallback = () => {
            if (document.body.scrollIntoView) {
              document.getElementById(key).scrollIntoView({ behavior: 'smooth' })
            }
          }

          return <tr key={key} onClick={scrollCallback}>
            <td className="error-table-key">{fieldLabels[key]}</td>
            <td className="error-table-value">{value}</td>
          </tr>
        })}
        </tbody>
      </table>
    </div>
  );
}

export default ErrorsSummary;
