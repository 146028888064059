import React, { useEffect, useState } from 'react'
import { Field, ErrorMessage, useFormikContext } from 'formik';
import { AWS_REGIONS } from '../../utils/regions';
import ValidationIndicator, {
  UNVALIDATED,
  WAITING_FOR_VALIDATION,
  VALID_CREDENTIALS,
  INVALID_CREDENTAILS
} from "../ValidationIndicator"
import fieldLabels from '../../utils/fieldLabels'
import ButtonWithMotionFeedback from '../ButtonWithMotionFeedback'
import { logEvent } from '../../utils/analyticsUtils';

/*
const realPromise = async () => {
  const res = await fetch("https://api.example.com/items");
  return await res.json();
}
*/

const fakePromise = async () => {
  await new Promise(resolve => {
    setTimeout(resolve, 600)
  })
  return {
    status: (Math.random() > 0.5) ? "FAILED" : "SUCCESS"
  }
}

function AWSInfoForm({ externalID }) {
  const {
    setFieldValue,
    setTouched,
    errors,
    values: { awsAccountId, awsRegion, tectonARN }
  } = useFormikContext();

  const [validationStatus, setValidationStatus] = useState(UNVALIDATED)

  const validateAWSAccess = () => {
    // Set waiting flag. Spinner will show based on flag
    setValidationStatus(WAITING_FOR_VALIDATION)

    logEvent('AWSInfoForm - validate');

    const awsValidated = 'awsValidated'

    fakePromise()
    .then(
      (result) => {
        if (result.status !== "SUCCESS") {
          setValidationStatus(INVALID_CREDENTAILS)
          setFieldValue(awsValidated, false)
        } else {
          setValidationStatus(VALID_CREDENTIALS)
          setFieldValue(awsValidated, true)
        }
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        console.log('handle fetch error')
      }
    )
  }

  // As soon as any of awsAccountId, awsRegion, or tectonARN changes,
  // mark the set as UNVALIDATED
  useEffect(() => {
    setValidationStatus(UNVALIDATED)
  }, [
    awsAccountId,
    awsRegion,
    tectonARN
  ])

  // Ready to Submit
  const isReady = (
    validationStatus !== INVALID_CREDENTAILS &&
    !errors.awsAccountId &&
    !errors.awsRegion &&
    !errors.tectonARN
  )

  const handleValidationButton = () => {
    if (isReady) {
      validateAWSAccess()
    } else {
      setTouched({
        awsAccountId: true,
        awsRegion: true,
        tectonARN: true
      }, true)
    }
  }

  return (
    <div id="awsValidated">
      <label htmlFor="awsAccountId">{fieldLabels['awsAccountId']}</label>
      <Field id="awsAccountId" type="text" name="awsAccountId" />
      <ErrorMessage name="awsAccountId" component="div" className="field-error-msg" />

      <label htmlFor="awsRegion">{fieldLabels['awsRegion']}</label>
      <Field id="awsRegion" type="text" name="awsRegion" as="select">
        <option value="" label="Select a region" />
        {AWS_REGIONS.map((region) => {
          return <option value={region} key={region} label={region} />
        })}
      </Field>
      <ErrorMessage name="awsRegion" component="div" className="field-error-msg" />

      <label htmlFor="tectonARN">{fieldLabels['tectonARN']}</label>
      <Field id="tectonARN" type="text" name="tectonARN" />
      <ErrorMessage name="tectonARN" component="div" className="field-error-msg" />

      <label htmlFor="awsExternalId">{fieldLabels['awsExternalId']}</label>
      <input type="text" id="awsExternalId" value={externalID} disabled />

      <ButtonWithMotionFeedback
        type="button"
        onClick={handleValidationButton}
        isReady={isReady}
      >
        Validate
      </ButtonWithMotionFeedback>

      <ValidationIndicator
        validationStatus={validationStatus}
        waitingMsg={"Connecting to AWS..."}
      />
    </div>
  )
}

export default AWSInfoForm
