import React, { useState } from 'react';

function FieldHelpIcon({ children }) {
  const [isShown, setShown] = useState(false)

  return (
    <span className="field-help">
      <button tabindex="-1"
        className={`field-help-button ${isShown ? 'field-help-button-active' : ''}`}
        onClick={() => {
          setShown(!isShown)
        }} type="button"
      >?</button>
      {isShown ?
      <div className="field-help-content">
        {children}

        <button className="field-help-content-close" onClick={() => setShown(false) }>Close</button>
      </div>
      : null}
    </span>
  );
}

export default FieldHelpIcon;
