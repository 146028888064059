import React, { useEffect, useRef } from 'react'
import { Formik, Form, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { Redirect } from 'react-router-dom'

import { initialValues, signupSchema } from './configs/formSchema';
import CompanyInfoForm from './CompanyInfoForm'
import TechnicalIntakeForm from './TechnicalIntakeForm'
import ReviewSubmission from '../ReviewSubmission';
import ButtonWithMotionFeedback from '../ButtonWithMotionFeedback';
import ErrorsSummary from '../ErrorsSummary';
import FormValidationFeedback from '../FormValidataionFeedback';
import CorpdataService from "../../utils/CorpdataService";
import TermsAndConditions from './TermsAndConditions';

const awsExternalID = uuidv4();

// Form Value Watcher watches the entire form as a whole
// and if any field in the form has been touched
// since the last error message, unset the error
// message at the bottom of the form
const FormValueChangeWatcher = ({
  unsetErrorMessage
}) => {
  const { values } = useFormikContext();

  const cachedValues = useRef({})

  useEffect(() => {
    const hasChanged = (values !== cachedValues.current)

    if (hasChanged) { unsetErrorMessage() }

    cachedValues.current = values
  })

  return null
}

const DEVONLYAutofillForm = ({
  formik
}) => {
  return <p onClick={() => {
    formik.setFieldValue('firstName', 'Tony', true)
    formik.setFieldValue('lastName', 'Chu', true)
    formik.setFieldValue('email', 'tony@tecton.ai', true)
    formik.setFieldValue('companyName', 'Tecton.ai', true)
    formik.setFieldValue('companySlug', 'tecton-ai', true)
    formik.setFieldValue('awsAccountId', '111222333444', true)
    formik.setFieldValue('awsRegion', 'us-east-2', true)
    formik.setFieldValue('primaryCloudProvider', ['AWS', 'GCP'], true)
    formik.setFieldValue('primaryComputeEngine', ['Databricks'], true)
    formik.setFieldValue('tectonARN', 'arn:aws:iam:testing-tecton-arn', true)
    formik.setFieldValue('glueCatalogAccountId', '111222333444', true)
    formik.setFieldValue('awsValidated', true, true)
    formik.setFieldValue('databricksSlug', 'aaa', true)
    formik.setFieldValue('databricksToken', 'aaa', true)
    formik.setFieldValue('databricksValidated', true, true)
    formik.setFieldValue('databricksARN', 'arn:aws:iam:testing-databricks-arn', true)
    formik.setFieldValue('dbARNValidated', true, true)
  }} style={{ cursor: "pointer" }}>TEST: Fill Form</p>
}

function SignupForm({
  pageState,
  sendPageTransition
}) {
  const setCustomerStack = ({ primaryCloudProvider, primaryComputeEngine }) => {
    const hasAWS = primaryCloudProvider instanceof Array && primaryCloudProvider.includes('AWS');
    const hasDatabricks = primaryComputeEngine instanceof Array && primaryComputeEngine.includes('Databricks');

    if (hasAWS && hasDatabricks) {
      sendPageTransition('SUITABLE_STACK')
    } else {
      sendPageTransition('UNSUPPORTED_STACK')
    }
  }

  const onSubmit = (values, { setSubmitting }) => {
    CorpdataService.sharedInstance().submitFinalForm(
      values.email,
      values.awsAccountId,
      values.awsRegion,
      values.tectonARN,
      awsExternalID,
      values.databricksSlug,
      values.databricksToken,
      values.databricksARN,
      values.glueCatalogAccountId
    )
    .then(() => {
      sendPageTransition('TECHNICAL_DETAILS_SUBMITTED_SUCCESSFULLY')
    })
    .catch((e) => {
      // If fetching from CorpdataService raises
      // an error, catch it here, and
      // show it as a message under the
      // giant submit button

      // TEST ONLY: TRANISITION ANYWAY
      const TEST_REDIRECT_WITHOUT_CORP_SERVER = true
      if (TEST_REDIRECT_WITHOUT_CORP_SERVER) {
        sendPageTransition('TECHNICAL_DETAILS_SUBMITTED_SUCCESSFULLY')
      } else {
        sendPageTransition({
          type: 'TECHNICAL_FORM_INVALID',
          message: e.message,
          source: 'server'
        })

        setSubmitting(false);
      }
    })
  }

  useEffect(() => {
    // Scroll into View after initial intake
    if (pageState.matches('technicalIntake.unsubmitted') && pageState.event.type === 'SUITABLE_STACK') {
      document.getElementById('lets-get-started').scrollIntoView({
        behavior: 'smooth'
      })
    }
  }, [pageState])

  // Redirects
  if (pageState.matches('submitted')) {
    return <Redirect to="/self-serve/success/" />
  }

  if (pageState.matches("comingSoon")) {
    return <Redirect to="/self-serve/coming-soon/" />
  }

  return <Formik
      initialValues={initialValues}
      validationSchema={signupSchema}
      onSubmit={onSubmit}
    >
    {(formik) => {
      const isReady = formik.isValid && !formik.isSubmitting

      return <Form>
    <div id="self-serve-signup-form" role="main">
      <section id="company-info" className="top-level-sections">
        <section className="topic-copy">
          <h1 id="main-title">Get Tecton for&nbsp;<span id="title-databrick">Databricks</span></h1>
          <p>
            marketing copy about Tecton on Databricks. Probably something about if you use Spark for Features,
            Tecton can help you deploy features faster and more reliably, while simplifying your model training
            pipelines at the same time.
          </p>
          <p>To set up Tecton, you will need:</p>
          <ul>
            <li>An AWS IAM role with permissions to use select AWS managed services.</li>
            <li>A Databricks API token.</li>
          </ul>
          <p>
            Tecton for Databricks uses our
            <a href="https://www.tecton.ai/pay-as-you-go/" target="_blank" rel="noopener noreferrer">Pay-as-you-go pricing plan.</a>
          </p>
        </section>
        <section className="form-section">
          <div id="company-info-form">
            <CompanyInfoForm
              pageState={pageState}
              setCustomerStack={setCustomerStack}
            />
          </div>
        </section>
      </section>

      {pageState.matches('technicalIntake') ? <>
        <TechnicalIntakeForm awsExternalID={awsExternalID} />
        <section className="top-level-sections">
          <h2>Pricing</h2>
        </section>
        <section className="top-level-sections" id="review">
          <h2>Let&rsquo;s Review Your Information</h2>
          <div id="information-review">
            <ReviewSubmission values={formik.values} />
          </div>
          <div id="submission">
            <ButtonWithMotionFeedback
              id="main-submit"
              isReady={isReady}
              type="submit"
              onClick={(e) => {
                if (!isReady) {
                  e.preventDefault()

                  sendPageTransition({
                    type: 'TECHNICAL_FORM_INVALID',
                    message: "There are missing form fields. Please complete the missing fields.",
                    source: 'client'
                  })
                } else {
                  sendPageTransition({
                    type: 'TECHNICAL_FORM_INITIATE_SUBMISSION'
                  })
                }
              }}
            >
              Create Account
            </ButtonWithMotionFeedback>
            <FormValidationFeedback
              isSubmitting={formik.isSubmitting}
              submissionWasInvalid={pageState.value?.technicalIntake === "formIsInvalid"}
              errorMessage={pageState.context?.errorMessage}
            />
            {(pageState.value?.technicalIntake === "formIsInvalid") ?
              <DEVONLYAutofillForm formik={formik} />
            : null}

            {(pageState.value?.technicalIntake === "formIsInvalid" && pageState.context.errorSource === 'client') ?
              <ErrorsSummary errors={formik.errors} />
            : null}

            <FormValueChangeWatcher
              unsetErrorMessage={() => {
                sendPageTransition('TECHNICAL_FORM_UPDATED')
              }}
            />
          </div>

          <TermsAndConditions />
        </section>
      </> : null }
      </div>
    </Form>
  }}
  </Formik>
}

export default SignupForm;
