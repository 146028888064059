import { createMachine, assign } from "xstate";

const ClusterValidationStateChart = {
  id: "cluster-validation-form-state",
  initial: "idle",
  context: {
    errorMessage: null
  },
  states: {
    idle: {
      on: {
        SUBMIT: 'processing'
      }
    },
    processing: {
      on: {
        CLUSTER_IS_VALID: 'cluster_valid',
        CLUSTER_IS_INVALID: {
          target: 'cluster_invalid',
          actions: assign((context, event) => {
            context.errorMessage = event.errorMessage
          })
        },
        UNABLE_TO_REACH_SERVER: 'unable_to_reach_server'
      }
    },
    cluster_valid: {},
    cluster_invalid: {
      on: {
        SUBMIT: {
          target: 'processing',
          actions: assign((context, event) => {
            context.errorMessage = null
          })
        }
      }
    },
    unable_to_reach_server: {
      on: {
        SUBMIT: 'processing'
      }
    }
  }
}

const ClusterValidationStateMachine = createMachine(ClusterValidationStateChart)

export default ClusterValidationStateMachine
export {
  ClusterValidationStateChart
}
