import React from 'react'
import {
  Switch,
  Route
} from "react-router-dom";
import { useMachine } from "@xstate/react";

import Form from './Form'
import SubmissionConfirmation from './SubmissionConfirmation'
import SorryWeDontSupportYourStackYet from './SorryWeDontSupportYourStackYet';

import SelfServePageStateMachine from '../../machine/SelfServePageStateMachine';

function SelfSelfSignupPage() {
  const [pageState, sendPageTransition] = useMachine(SelfServePageStateMachine);

  return <Switch>
    <Route exact path="/self-serve">
      <Form
        pageState={pageState}
        sendPageTransition={sendPageTransition}
      />
    </Route>
    <Route path="/self-serve/success">
      <SubmissionConfirmation />
    </Route>
    <Route path="/self-serve/coming-soon">
      <SorryWeDontSupportYourStackYet />
    </Route>
  </Switch>
}

export default SelfSelfSignupPage;
