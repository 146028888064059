import React, { useEffect, useState } from 'react'

const useButtonNotReady = () => {
  const [isNotReady, setIsNotReady] = useState(false)

  useEffect(() => {
    if (isNotReady) {
      setTimeout(() => {
        setIsNotReady(false)
      }, 400);
    }
  }, [isNotReady])

  return [
    isNotReady,
    setIsNotReady
  ]
}

const buttonClassFn = ({ isReady, isNotReady }) => {
  return ((isReady) ? 'is-ready' : '') + ((isNotReady) ? ' is-not-ready' : '')
}

function ButtonWithMotionFeedback({ isReady, onClick, children, className, type, ...props }) {
  const [isNotReady, setIsNotReady] = useButtonNotReady()

  return (
    <button
      {...props}
      className={'form-submission-button ' + className + ' ' + buttonClassFn({ isReady, isNotReady })}
      onClick={(e) => {
        if (!isReady) { setIsNotReady(true) }
        if (onClick) {
          onClick(e)
        }
      }}
      type={type}
    >
      {children}
    </button>
  );
}

export default ButtonWithMotionFeedback
export { useButtonNotReady, buttonClassFn }
