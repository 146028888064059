import * as Yup from 'yup';

const initialValues = {
    // Company Info
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    companySlug: '',
    primaryCloudProvider: '',
    primaryComputeEngine: '',

    // AWS
    awsAccountId: '',
    awsRegion: '',
    tectonARN: '',
    // awsValidated: false,

    glueCatalogAccountId: '',

    // Databricks
    databricksSlug: '',
    databricksToken: '',
    // databricksValidated: false,

    // Databricks ARN
    databricksARN: '',
    // dbARNValidated: false
  }

  /*
  // Validation Regex from backend
  private val AWS_ACCOUNT_ID_REGEX = Regex("\\d{12}")
  private val AWS_REGION_REGEX = Regex("[a-z]{2}-[a-z]+-\\d")
  private val COMPANY_IDENTIFIER_REGEX = Regex("[a-z0-9_-]{3,22}")
  private val BASIC_ARN_REGEX = Regex("arn:aws:iam:.*")

  // Useful for Testing: https://regex101.com/
  */
  const AWS_ACCOUNT_ID_REGEX = /^\d{12}$/
  const AWS_REGION_REGEX = /[a-z]{2}-[a-z]+-\d/
  const COMPANY_IDENTIFIER_REGEX = /^[a-z0-9_-]{3,22}$/
  const BASIC_ARN_REGEX = /^arn:aws:iam:.*/

  const REQUIRED = 'Required'

  const signupSchema = Yup.object().shape({
    firstName: Yup.string().required(REQUIRED),
    lastName: Yup.string().required(REQUIRED),
    email: Yup.string().email('Invalid email').required(REQUIRED),
    companyName: Yup.string().required(REQUIRED),
    companySlug: Yup.string().trim().matches(COMPANY_IDENTIFIER_REGEX, {
      message: "Must be a alphanumeric string between 3-22 characters long."
    }).required(REQUIRED),
    primaryCloudProvider: Yup.array().required(REQUIRED),
    primaryComputeEngine: Yup.array().required(REQUIRED),

    // AWS
    awsAccountId: Yup.string().trim().matches(AWS_ACCOUNT_ID_REGEX, {
      message: "AWS Account ID must be 12 digits long."
    }).required(REQUIRED),
    awsRegion: Yup.string().trim().matches(AWS_REGION_REGEX, {
      message: "AWS Region is invalid."
    }).required(REQUIRED),
    tectonARN: Yup.string().trim().matches(BASIC_ARN_REGEX, {
      message: "Invalid ARN string format"
    }).required(REQUIRED),
    // TODO, eventually: validate this remotely
    // awsValidated: Yup.boolean()
    //   .required(REQUIRED)
    //   .oneOf([true], 'Please validate your AWS access credentials.'),

    glueCatalogAccountId: Yup.string(),

    // Databricks
    databricksSlug: Yup.string().trim().required(REQUIRED),
    databricksToken: Yup.string().trim().required(REQUIRED),
    // TODO, eventually: validate this remotely
    // databricksValidated: Yup.boolean()
    //   .required(REQUIRED)
    //   .oneOf([true], 'Please validate your Databricks access credentials.'),

    // Databricks ARN
    databricksARN: Yup.string().trim().matches(BASIC_ARN_REGEX, {
      message: "Invalid ARN string format"
    }).required(REQUIRED),
    // TODO, eventually: validate this remotely
    // dbARNValidated: Yup.boolean()
    //   .required(REQUIRED)
    //   .oneOf([true], 'Please validate your Databricks ARN.')
  });

  export { initialValues, signupSchema }
