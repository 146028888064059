import React from 'react';

function TermsAndConditions(props) {
  return (
    <section id="terms-and-conditions">
      <h3>TERMS AND CONDITIONS</h3>
      <p>FIRST DAY OF USE OF SERVICES. Defined as the day on which Customer’s initial data sources have been connected to Tecton.</p>

      <p>PAYMENT TERMS. Customer will be invoiced in accordance with the Billing Frequency and Payment Terms set forth above.</p>

      <p>SUBSCRIPTION TERMS. Customer’s purchase of, and rights to use, the Services are subject to the provisions of this Order
        Form as well as the Platform-as-a-Service Agreement (the “Agreement”),
        <a href="https://www.tecton.ai/tecton_online-tos/" target="_blank" rel="noopener noreferrer" style={{ fontSize: "15px"}}>available here</a>.
        This Order Form is incorporated by reference into the Agreement.  Any capitalized term used but not defined in
        this Order Form shall have the meaning assigned to such term in the Agreement.  If any provision of this Order
        Form conflicts with a provision of the Agreement, the provision in this Order Form prevails, but only to the
        extent it relates to the rights and obligations of the parties under this Order Form.  No term in any Customer
        urchase order or similar document will be deemed to modify the Agreement unless preauthorized in writing by Tecton.
      </p>

      <p>Upon clicking the “Submit” button above, this Order Form shall become legally binding as of today’s date and governed by the Agreement between Tecton and Customer.</p>
    </section>
  );
}

export default TermsAndConditions;
