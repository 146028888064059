class CDSError {
  constructor(message) {
    this.message = message;
  }
}

export default class CorpdataService {
  constructor() {
    this.serviceURL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "/api";
  }

  static sharedInstance() {
    return instance;
  }

  async _callCDS(path, params) {
    const url = instance.serviceURL + path;
    const fetchParams = {
      method: 'post',
      credentials: 'include',
      mode: 'cors', // no-cors, cors, *same-origin
      body: JSON.stringify(params)
    };
    return fetch(url, fetchParams);
  }

  async checkResponse(response) {
    if (response.ok || response.status === 200) {
      return;
    }
    const errorJson = await response.json();
    const message = errorJson.message;
    console.log('CDS error: ' + message);
    throw new CDSError(message);
  }

  async submitLeadForm(email, firstName, lastName, companyName, companyId, primaryCloudProvider, primaryComputeEngine) {
    const response = await instance._callCDS('/v1/corpdata-service/submit-cluster-signup-lead-form', {
      email: email,
      first_name: firstName,
      last_name: lastName,
      company_legal_name: companyName,
      company_identifier: companyId,
      primary_cloud_provider: primaryCloudProvider,
      primary_compute_engine: primaryComputeEngine
    });
    await instance.checkResponse(response);
    return response;
  }

  async ingestAnalytics(events) {
    await instance._callCDS('/v1/corpdata-service/ingest-analytics', {
      events: events
    });
  }

  async submitFinalForm(email, awsAccountId, awsRegion, tectonRoleArn, externalId, databricksAccountId, databricksAccessToken, databricksInstanceProfileArn, glueCatalogAccountId) {
    const response = await instance._callCDS('/v1/corpdata-service/submit-cluster-signup-final-form', {
      email: email,
      aws_account_id: awsAccountId,
      aws_region: awsRegion,
      tecton_role_arn: tectonRoleArn,
      external_id: externalId,
      databricks_account_id: databricksAccountId,
      databricks_access_token: databricksAccessToken,
      databricks_instance_profile_arn: databricksInstanceProfileArn,
      glue_catalog_account_id: glueCatalogAccountId
    });
    await instance.checkResponse(response);
    return response;
  }

  async submitClusterValidationForm(region, cross_account_role_external_id, cross_account_role_arn, spark_role_arn, spark_instance_profile_arn, databricks_api_token, emr_master_role_arn, deployment_name) {
    const response = await instance._callCDS('/v1/corpdata-service/validate-cluster-form', {
      region: region,
      cross_account_role_external_id: cross_account_role_external_id,
      cross_account_role_arn: cross_account_role_arn,
      spark_role_arn: spark_role_arn,
      spark_instance_profile_arn: spark_instance_profile_arn,
      databricks_api_token: databricks_api_token,
      emr_master_role_arn: emr_master_role_arn,
      deployment_name: deployment_name
    });
    await instance.checkResponse(response);
    const responseJson = await response.json();
    return responseJson;
  }
}

const instance = new CorpdataService();
