import React from 'react';

function SubmissionConfirmation() {
  return (
    <section  className="top-level-sections">
      <div id="confirmation">
        <h1>Thank you!</h1>
        <p>
          We have received your AWS and Databricks information, and will begin
          provisioning clusters for you shortly. We will notify you via email
          with details about logging in, or follow up with questions if we run
          into trouble with credentials within 24 hours.
        </p>
        <p>In the meantime, <a href="https://docs.tecton.ai/">please take a look
          at our docs</a>. We are excited to help you get your ML infrastructure
          set up on a solid foundation.
        </p>
        <p>Your Tecton Team</p>
      </div>
    </section>
  );
}

export default SubmissionConfirmation;
