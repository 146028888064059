import React from 'react'

import './App.css';
import {
  Switch,
  Route,
  useHistory
} from "react-router-dom";
import SelfServeSignupPage from "./components/SelfServePage/SelfServeSignupPage"
import ClusterValidationPage from './components/ClusterValidationPage/ClusterValidationPage';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import OktaConfig from './utils/okta';

const oktaAuth = new OktaAuth(OktaConfig.oidc);

function App() {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <div>
        <header>
          <a id="logo-link" href="http://tecton.ai">
            <img src="/tecton-logo-red.svg" alt="Tecton Logo" />
          </a>
        </header>
        <Switch>
          <Route path="/login/callback" exact component={LoginCallback} errorComponent={() => {
            oktaAuth.signOut()
            return null;
          }} />
          <Route path="/" exact render={() => (window.location = "https://www.tecton.ai/")}>
          </Route>
          <SecureRoute path="/self-serve">
            <SelfServeSignupPage />
          </SecureRoute>
          <SecureRoute path="/validate">
            <ClusterValidationPage />
          </SecureRoute>
        </Switch>
      </div>
    </Security>
  )
}

export default App;
