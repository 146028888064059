import React, { useState } from 'react';
import { useFormikContext } from 'formik'

function Drawer({ label, children }) {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => { setIsOpen(!isOpen) }
  const labelClassName = (isOpen) ? 'drawer-is-open' : ''

  return <>
    <p className={`drawer-label ${labelClassName}`} onClick={toggle}>
      {label} &mdash; <span className="drawer-action-indicator">
        {(!isOpen) ? 'Show' : 'Hide' }
      </span>
    </p>
    <div style={{ display: (isOpen) ? 'block' : 'none' }}>
      {children}
    </div>
  </>
}

function PolicyTemplate() {
  const {
    values: { tectonARN },
  } = useFormikContext();

  return (
    <>
      <Drawer label={<>Permissions Policy</>}>
        <code>
{`{
  "Version": "2012-10-17",
  "Statement": [
    {
      "Effect": "Allow",
      "Action": "service-prefix:action-name",
      "Resource": "${tectonARN || 'XXXXXXXXXXXXXX'}",
      "Condition": {
        "DateGreaterThan": {"aws:CurrentTime": "2020-04-01T00:00:00Z"},
        "DateLessThan": {"aws:CurrentTime": "2020-06-30T23:59:59Z"}
      }
    }
  ]
}`}
        </code>
      </Drawer>
      <Drawer label={<>Trust Relationship Policy</>}>
        <code>
{`{
  "Version": "2012-10-17",
  "Statement": [
    {
      "Effect": "Allow",
      "Action": "service-prefix:action-name",
      "Resource": "${tectonARN || 'XXXXXXXXXXXXXX'}",
      "Condition": {
        "DateGreaterThan": {"aws:CurrentTime": "2020-04-01T00:00:00Z"},
        "DateLessThan": {"aws:CurrentTime": "2020-06-30T23:59:59Z"}
      }
    }
  ]
}`}
        </code>
      </Drawer>
    </>
  );
}

export default PolicyTemplate;
