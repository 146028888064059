import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Persist } from 'formik-persist'
import { useMachine } from "@xstate/react"

import ClusterValidationStateMachine from '../../machine/ClusterValidationStateMachine';
import { AWS_REGIONS } from '../../utils/regions'
import { initialValues, clusterFormSchema } from './configs/formSchema';
import CorpdataService from '../../utils/CorpdataService';

import ValidationFeedback from './ValidationFeedback'
import ButtonWithMotionFeedback from '../ButtonWithMotionFeedback'
import FieldHelpIcon from '../FieldHelpIcon';

function ClusterValidationPage(props) {
  const [state, send] = useMachine(ClusterValidationStateMachine)

  const onSubmit = (values, { setSubmitting }) => {
    if (state.matches('processing')) { return }

    send('SUBMIT')

    const {
      region,
      cross_account_role_external_id,
      cross_account_role_arn,
      spark_role_arn,
      spark_instance_profile_arn,
      databricks_api_token,
      emr_master_role_arn,
      deployment_name } = values

    CorpdataService.sharedInstance().submitClusterValidationForm(
      region,
      cross_account_role_external_id,
      cross_account_role_arn,
      spark_role_arn,
      spark_instance_profile_arn,
      databricks_api_token,
      emr_master_role_arn,
      deployment_name
    )
    .then((response) => {
      if (response.is_success) {
        send('CLUSTER_IS_VALID');
      } else {
        send('CLUSTER_IS_INVALID', {
          errorMessage: response.error_message
        })
      }
    })
    .catch((e) => {
      // TODO: handle internal error
      send('UNABLE_TO_REACH_SERVER')
    })
    .finally(() => {
      setSubmitting(false)
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={clusterFormSchema}
      onSubmit={onSubmit}
    >
      {(props) => {
        const isReady =  Object.keys(props.errors).length === 0
                      && Object.keys(props.touched).length > 0
                      && !(state.matches('processing') || state.matches('cluster_valid'))

        return <Form>
          <section className="top-level-sections credential-sections" id="validation-form">
            <section className="topic-copy">
              <h1>Tecton Pre-Deployment Validation Form</h1>
              <ValidationFeedback state={state} />
              <p>
                This form validates the
                <a href="https://docs.tecton.ai/docs/setting-up-tecton/connecting-to-a-data-platform/tecton-on-databricks/configuring-databricks" target="_blank" rel="noopener noreferrer"> information we need for installing Tecton</a>.
                Fulll installation instructions:
              </p>
              <ul>
                <li><a href="https://docs.tecton.ai/docs/setting-up-tecton/connecting-to-a-data-platform/tecton-on-databricks/configuring-databricks" target="_blank" rel="noopener noreferrer">Databricks</a></li>
                <li><a href="https://docs.tecton.ai/docs/setting-up-tecton/connecting-to-a-data-platform/tecton-on-emr/configuring-emr" target="_blank" rel="noopener noreferrer">EMR</a></li>
              </ul>
            </section>
            <section className="form-section">
              <div>
                <label htmlFor="region">AWS Region
                  <FieldHelpIcon>
                    The AWS Region where your Tecton cluster will live.
                  </FieldHelpIcon>
                </label>
                <Field id="region" type="text" name="region" as="select">
                  <option value="" label="Select a region" />
                  {AWS_REGIONS.map((region) => {
                    return <option value={region} key={region} label={region} />
                  })}
                </Field>
                <ErrorMessage name="region" component="div" className="field-error-msg" />

                <label htmlFor="deployment_name">Deployment Name
                  <FieldHelpIcon>
                    The name of your Tecton deployment.
                  </FieldHelpIcon>
                </label>
                <Field id="deployment_name" type="text" name="deployment_name" />
                <ErrorMessage name="deployment_name" component="div" className="field-error-msg" />

                <label htmlFor="cross_account_role_arn">Cross Account Role ARN
                  <FieldHelpIcon>
                    Full ARN of the role tecton-DEPLOYMENT_NAME-cross-account-role.
                  </FieldHelpIcon>
                </label>
                <Field id="cross_account_role_arn" type="text" name="cross_account_role_arn" />
                <ErrorMessage name="cross_account_role_arn" component="div" className="field-error-msg" />

                <label htmlFor="cross_account_role_external_id">Cross Account Role External ID
                  <FieldHelpIcon>
                    The External ID of the role tecton-DEPLOYMENT_NAME-cross-account-role.
                  </FieldHelpIcon>
                </label>
                <Field id="cross_account_role_external_id" type="text" name="cross_account_role_external_id" />
                <ErrorMessage name="cross_account_role_external_id" component="div" className="field-error-msg" />

                <label htmlFor="spark_role_arn">Spark Role ARN
                  <FieldHelpIcon>
                    The AWS role used by your Spark instances (SPARK_ROLE in the docs)
                  </FieldHelpIcon>
                </label>
                <Field id="spark_role_arn" type="text" name="spark_role_arn" />
                <ErrorMessage name="spark_role_arn" component="div" className="field-error-msg" />

                <label htmlFor="spark_instance_profile_arn">Spark Instance Profile ARN
                  <FieldHelpIcon>
                    The AWS instance profile used by your Spark instances (attached to SPARK_ROLE in the docs).
                  </FieldHelpIcon>
                </label>
                <Field id="spark_instance_profile_arn" type="text" name="spark_instance_profile_arn" />
                <ErrorMessage name="spark_instance_profile_arn" component="div" className="field-error-msg" />

                <label htmlFor="databricks_api_token">Databricks API token (Databricks only, leave empty for EMR)
                  <FieldHelpIcon>
                    The Databricks API token that has cluster-create privileges in your Databricks instance
                  </FieldHelpIcon>
                </label>
                <Field id="databricks_api_token" type="text" name="databricks_api_token" />
                <ErrorMessage name="databricks_api_token" component="div" className="field-error-msg" />

                <label htmlFor="emr_master_role_arn">EMR Manager Role ARN (EMR only, leave empty for Databricks)
                  <FieldHelpIcon>
                    Full ARN of the role tecton-DEPLOYMENT_NAME-emr-manager-role.
                  </FieldHelpIcon>
                </label>
                <Field id="emr_master_role_arn" type="text" name="emr_master_role_arn" />
                <ErrorMessage name="emr_master_role_arn" component="div" className="field-error-msg" />

                <ButtonWithMotionFeedback
                  isReady={isReady}
                  type={"submit"}
                >
                  Submit
                </ButtonWithMotionFeedback>
              </div>
            </section>
          </section>
          <Persist name="tecton-cluster-validation-form" />
        </Form>
      }}
    </Formik>
  );
}

export default ClusterValidationPage;
