import { createMachine, assign } from "xstate";
import { logEvent } from '../utils/analyticsUtils';

// Xstate: https://xstate.js.org/docs/
const technicalFormMachine = {
  id: "technicalFormState",
  initial: "unsubmitted",
  context: {
    errorMessage: '',
    isClientValidation: undefined
  },
  states: {
    unsubmitted: {
      on: {
        TECHNICAL_FORM_INITIATE_SUBMISSION: {
          target: 'submitting'
        },
        TECHNICAL_FORM_INVALID: {
          entry: () => {
            logEvent('Submit - Form Invalid (Client Side Validation)')
          },
          target: "formIsInvalid",
          actions: assign((context, event) => {
            context.errorMessage = event.message
            context.errorSource = event.source
          })
        }
      }
    },
    submitting: {
      on: {
        TECHNICAL_FORM_INVALID: {
          entry: () => {
            logEvent('Submit - Form Invalid (Server Side Validation)')
          },
          target: "formIsInvalid",
          actions: assign((context, event) => {
            context.errorMessage = event.message
            context.errorSource = event.source
          })
        }
      }
    },
    formIsInvalid: {
      on: {
        TECHNICAL_FORM_UPDATED: {
          target: "unsubmitted",
          actions: assign((context) => {
            context.errorMessage = ''
            context.errorSource = undefined
          })
        }
      }
    }
  }
}

const SelfServePageStateMachine = createMachine({
  id: "signupState",
  initial: "initialIntake",
  states: {
    initialIntake: {
      entry: [
        () => {
          logEvent('Lands on Self Serve Cluster Signup Page');
        }
      ],
      on: {
        SUITABLE_STACK: "technicalIntake",
        UNSUPPORTED_STACK: "comingSoon"
      }
    },
    technicalIntake: {
      on: {
        TECHNICAL_DETAILS_SUBMITTED_SUCCESSFULLY: "submitted"
      },
      ...technicalFormMachine
    },
    submitted: {
      entry: () => {
        logEvent('Submit - Form Submitted Successfully')
      }
    },
    comingSoon: {
      entry: () => {
        logEvent('Submit - Form Submitted Successfully (Not Suitable Stack)')
      }
    },
  }
});

export default SelfServePageStateMachine
