import * as Yup from 'yup'
import { AWS_REGIONS } from '../../../utils/regions';

const REQUIRED = 'Required'
// Based on https://docs.aws.amazon.com/IAM/latest/APIReference/API_CreateRole.html
const ROLE_ARN_REGEX = /^arn:aws:iam:/

const initialValues = {
  region: "",
  cross_account_role_external_id: "",
  cross_account_role_arn: "",
  spark_role_arn: "",
  spark_instance_profile_arn: "",
  databricks_api_token: "",
  emr_master_role_arn: "",
  deployment_name: ""
}

const clusterFormSchema = Yup.object().shape({
  region: Yup.mixed().oneOf(AWS_REGIONS).required(REQUIRED),
  cross_account_role_external_id: Yup.string().required(REQUIRED),
  cross_account_role_arn: Yup.string().trim().matches(ROLE_ARN_REGEX, {
    message: "Invalid ARN format"
  }).required(REQUIRED),
  spark_role_arn: Yup.string().trim().matches(ROLE_ARN_REGEX, {
    message: "Invalid ARN format"
  }).required(REQUIRED),
  spark_instance_profile_arn: Yup.string().trim().matches(ROLE_ARN_REGEX, {
    message: "Invalid ARN format"
  }).required(REQUIRED),
  databricks_api_token: Yup.string().trim(),
  emr_master_role_arn: Yup.string().trim().matches(ROLE_ARN_REGEX, {
    message: "Invalid ARN format"
  }),
  deployment_name: Yup.string().required(REQUIRED)
})

export { initialValues, clusterFormSchema }
