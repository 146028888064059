import React, { useEffect } from 'react'
import { Field, ErrorMessage, useFormikContext } from 'formik';
import { createMachine } from "xstate";
import { useMachine } from "@xstate/react";
import slugify from 'slugify'

import fieldLabels from '../../utils/fieldLabels'
import ButtonWithMotionFeedback from '../ButtonWithMotionFeedback'
import CorpdataService from "../../utils/CorpdataService";
import { logEvent, useValueChangeLogger, setLoggedEmail } from '../../utils/analyticsUtils';

const CLOUD_OPTIONS = [
  "AWS",
  "GCP",
  "Azure",
  "Other Cloud",
  "On Premises"
]

const COMPUTE_OPTIONS = [
  "Databricks",
  "EMR",
  "Redshift",
  "Snowflake",
  "Python",
  "Other"
]

const URLtransform = (string) => {
  // Turns the Company String into a slug
  return slugify(string).toLocaleLowerCase().replace('.', '-')
}

const submitLeadForm = ({ email, firstName, lastName, companyName, companySlug, primaryCloudProvider, primaryComputeEngine }) => {
  setLoggedEmail(email);

  return CorpdataService.sharedInstance().submitLeadForm(email, firstName, lastName, companyName, companySlug, primaryCloudProvider, primaryComputeEngine)
}

const leadsFormStateChart = {
  id: "leadsFormState",
  initial: "unsubmitted",
  context: {
    retries: 0
  },
  states: {
    unsubmitted: {
      on: {
        LEADS_FORM_INITIATE_SUBMISSION: {
          target: "submitting",
          actions: (context, event) => {
            logEvent('Submit - Company Info Form');
          }
        }
      }
    },
    submitting: {
      // https://xstate.js.org/docs/guides/communication.html#invoking-promises
      invoke: {
        id: 'submitLeadsForm',
        src: (context, event) => {
          return submitLeadForm(event.fields)
        },
        onDone: {
          target: 'success',
        },
        onError: {
          // What if this fails, but the form submission succeeds?
          target: 'failure',
        }
      }
    },
    success: {},
    failure: {}
  }
}

const leadsFormMachine = createMachine(leadsFormStateChart)
const checkReadiness = (errors, formState) => {
  return (
    !errors.companySlug &&
    !errors.companyName &&
    !errors.firstName &&
    !errors.lastName &&
    !errors.email &&
    !errors.primaryCloudProvider &&
    !errors.primaryComputeEngine &&
    formState.matches('unsubmitted')
  )
}

function CompanyInfoForm({
  setCustomerStack
}) {
  const {
    setTouched,
    setFieldValue,
    values: { email, firstName, lastName, companyName, companySlug, primaryCloudProvider, primaryComputeEngine },
    validateForm,
    errors
  } = useFormikContext();

  const [leadsFormState, send] = useMachine(leadsFormMachine)

  useValueChangeLogger(firstName, "Edit – First Name")
  useValueChangeLogger(lastName, "Edit – Last Name")
  useValueChangeLogger(email, "Edit – Email Address")
  useValueChangeLogger(companyName, "Edit – Company Name")
  useValueChangeLogger(primaryCloudProvider, "Edit – Cloud Provider")
  useValueChangeLogger(primaryComputeEngine, "Edit – Compute Engine")

  // Suggest Company Slug
  useEffect(() => {
    if (companyName) {
      setFieldValue("companySlug", URLtransform(companyName))
    }
  }, [companyName, setFieldValue])

  // Ready to Submit
  const isReady = checkReadiness(errors, leadsFormState)

  const handleNextButton = () => {
    validateForm().then((e) => {
      const isReady = checkReadiness(e, leadsFormState)

      if (isReady) {
        send({
          type: 'LEADS_FORM_INITIATE_SUBMISSION',
          fields: {
            email,
            firstName,
            lastName,
            companyName,
            companySlug,
            primaryCloudProvider,
            primaryComputeEngine
          }
        })

        setCustomerStack({ primaryCloudProvider, primaryComputeEngine })
      } else {
        setTouched({
          email: true,
          firstName: true,
          lastName: true,
          companyName: true,
          companySlug: true,
          primaryCloudProvider: true,
          primaryComputeEngine: true
        }, true)
      }
    })
  }

  return (
    <>
      <label htmlFor="firstName">{fieldLabels['firstName']}</label>
      <Field id="firstName" type="string" name="firstName" />
      <ErrorMessage name="firstName" component="div" className="field-error-msg" />

      <label htmlFor="lastName">{fieldLabels['lastName']}</label>
      <Field id="lastName" type="string" name="lastName" />
      <ErrorMessage name="lastName" component="div" className="field-error-msg" />

      <label htmlFor="email">{fieldLabels['email']}</label>
      <Field id="email" type="email" name="email" />
      <ErrorMessage name="email" component="div" className="field-error-msg" />

      <label htmlFor="companyName">{fieldLabels['companyName']}</label>
      <Field id="companyName" type="string" name="companyName" />
      <ErrorMessage name="companyName" component="div" className="field-error-msg" />

      <label htmlFor="companySlug">{fieldLabels['companySlug']}</label>
      <Field id="companySlug" type="string" name="companySlug" />
      <ErrorMessage name="companySlug" component="div" className="field-error-msg" />

      <label htmlFor="primaryCloudProvider">
        {fieldLabels['primaryCloudProvider']}
        <ErrorMessage name="primaryCloudProvider" component="span" className="field-error-msg" />
      </label>
      <div id="primaryCloudProvider" role="group" className="checkbox-group" aria-labelledby="checkbox-group">
        {CLOUD_OPTIONS.map((d) => {
          return <label key={d}>
            <Field type="checkbox" name="primaryCloudProvider" value={d} />
            {d}
          </label>
        })}
      </div>


      <label htmlFor="primaryComputeEngine">
        {fieldLabels['primaryComputeEngine']}
        <ErrorMessage name="primaryComputeEngine" component="span" className="field-error-msg" />
      </label>
      <div id="primaryComputeEngine" role="group" className="checkbox-group" aria-labelledby="checkbox-group">
        {COMPUTE_OPTIONS.map((d) => {
          return <label key={d}>
            <Field type="checkbox" name="primaryComputeEngine" value={d} />
            {d}
          </label>
        })}
      </div>

      <ButtonWithMotionFeedback
        isReady={isReady}
        onClick={handleNextButton}
        type={"button"}
      >
        Next
      </ButtonWithMotionFeedback>

      <p style={{ marginTop: '1rem' }}>Next, we will fill out deployment details in the following sections.</p>
    </>
  )
}

export default CompanyInfoForm
