import React, { useEffect, useState } from 'react'
import { Field, ErrorMessage, useFormikContext } from 'formik';
import ButtonWithMotionFeedback from '../ButtonWithMotionFeedback'
import fieldLabels from '../../utils/fieldLabels'
import ValidationIndicator, {
  UNVALIDATED,
  WAITING_FOR_VALIDATION,
  VALID_CREDENTIALS,
  INVALID_CREDENTAILS
} from "../ValidationIndicator"

/*
const realPromise = async () => {
  const res = await fetch("https://api.example.com/items");
  return await res.json();
}
*/

const fakePromise = async () => {
  await new Promise(resolve => {
    setTimeout(resolve, 300)
  })
  return {
    status: (Math.random() > 0.5) ? "FAILED" : "SUCCESS"
  }
}

function DatabricksARNform() {
  const {
    setTouched,
    setFieldValue,
    values: {
      databricksARN
    },
    errors
  } = useFormikContext()

  const [validationStatus, setValidationStatus] = useState(UNVALIDATED)

  const validateCredentials = () => {
    // Set waiting flag. Spinner will show based on flag
    setValidationStatus(WAITING_FOR_VALIDATION)

    const dbARNValidated = 'dbARNValidated'

    fakePromise()
    .then(
      (result) => {
        if (result.status !== "SUCCESS") {
          // Validation Failed
          setValidationStatus(INVALID_CREDENTAILS)
          setFieldValue(dbARNValidated, false)
        } else {
          // Validation Succeeds
          setValidationStatus(VALID_CREDENTIALS)
          setFieldValue(dbARNValidated, true)
        }
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        console.log('handle fetch error')
      }
    )
  }

  // Ready to Submit
  const isReady = (
    validationStatus !== INVALID_CREDENTAILS &&
    !errors.databricksARN
  )

  const handleValidationButton = () => {
    if (isReady) {
      validateCredentials()
    } else {
      setTouched({
        databricksARN: true
      }, true)
    }
  }

  // As soon as any of awsAccountId, awsRegion, or tectonARN changes,
  // mark the set as UNVALIDATED
  useEffect(() => {
    setValidationStatus(UNVALIDATED)
  }, [
    databricksARN,
  ])

  return (
    <div id="dbARNValidated">
      <label htmlFor="databricksARN">{fieldLabels['databricksARN']}</label>
      <Field id="databricksARN" type="databricksARN" name="databricksARN" />
      <ErrorMessage name="databricksARN" component="div" className="field-error-msg" />

      <ButtonWithMotionFeedback
        type="button"
        onClick={handleValidationButton}
        isReady={isReady}
      >
        Validate
      </ButtonWithMotionFeedback>

      <ValidationIndicator validationStatus={validationStatus} />
    </div>
  )
}

export default DatabricksARNform
